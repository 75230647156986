<template>
    <ApexChart type="area" :height="height || 350" :options="_options" :series="series"></apexchart>
</template>

<script>

    export default {
        props: ['height', 'width', 'chart', 'series', 'labels', 'labelFormat', 'sparkline', 'yLabelFormat'],
        data() {
            return {
                _options: {
                    chart: {
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        },
                        sparkline: {
                            enabled: this.sparkline ? this.sparkline : false
                        },
                    },
                    legend: {
                        show: false
                    },
                    stroke: {
                        width: 2,
                    },
                    labels: this.labels,
                    yaxis: {
                        show: false,
                        labels: {
                            formatter: (val) => {
                                if(this.yLabelFormat) return this.yLabelFormat(val);
                                return val;
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: (val) => {
                            if(this.labelFormat) return this.labelFormat(val);
                            return val;
                        },
                        offsetY: 0,
                        style: {
                            fontSize: '9px',
                            colors: ['#5864FF']
                        },
                        background: {
                            enabled: true,
                            borderWidth: 0,
                            foreColor: '#fff',
                            opacity: 1
                        }
                    },
                    grid: {
                        show: true,      // you can either change hear to disable all grids
                        borderColor: 'rgba(0,0,0,0.05)',
                        xaxis: {
                            lines: {
                                show: true  //or just here to disable only x axis grids
                            }
                        },  
                        yaxis: {
                            lines: { 
                                show: false  //or just here to disable only y axis
                            }
                        },   
                    },
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.chart {
    width: 100%;
}
</style>