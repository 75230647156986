<template>
    <div class="modal-wrapper">

        <Table :head="[{ name: 'Name' }, { name: 'Date of Birth' }, { name: 'Ignore Stats' }, { name: 'Hide' }]" :stickyHead="true" :size="['minmax(0, 1fr)', 'minmax(100px, 150px)', '75px', '34px']">
            <TableRow :size="['minmax(0, 1fr)', 'minmax(100px, 150px)', '75px', '34px']" :key="item._id" v-for="(item, index) of users" >
                <div class="dispatcher-name"><a href="" @click.prevent="">{{ item.name }}</a></div>
                <div><DropDownDatePicker class="dob-selector" position="center" @selection="(dob) => { dobSelected(item.id, dob) }" v-model="item.dob" placeholder="—"/></div>
                <div style="display: flex; justify-content: center; align-items: center;"><Switch v-model="item.ignore" @change="(val) => { changeIgnoreOption(item.id, val) }"/></div>
                <button class="hide-user-btn" @click="hideUser(item.id, index)"><i class="fa-solid fa-eye-slash"></i></button>
            </TableRow>
        </Table>

    </div>
</template>

<script>
import moment from 'moment';
import Dispatchers from '../../mixins/Dispatchers';

    export default {
        mixins: [Dispatchers],
        props: ['modal'],
        data() {
            return {
                users: []
            }
        },
        methods: {
            async getUsers() {
                this.modal.processing(true);
                await this.users_get((err, body) => {
                    if(!err) {
                        this.users = body;
                    }
                });
                this.modal.processing(false);
            },
            async hideUser(id, key) {
                let isConfirmed = await this.$ShowConfirm('Do you really wish to hide this user?');

                if(!isConfirmed) return;

                this.users.splice(key, 1);

                this.modal.processing(true);
                await this.users_set_hidden_option(id, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                    }
                });
                this.modal.processing(false);
                
            },
            async changeIgnoreOption(id, val) {
                this.modal.processing(true);
                await this.users_set_ignore_option(id, val, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                    }
                });
                this.modal.processing(false);
            },
            async dobSelected(id, date) {
                this.modal.processing(true);

                console.log(date);

                let d = moment(date).format("YYYY-MM-DD");
                await this.users_set_dob(id, d, (err, body) => {
                    if(err) {
                        alert(body.message || 'Something went wrong. Please, try again!');
                    }
                });
                this.modal.processing(false);
            }
        },
        mounted() {
            this.getUsers();
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {

}

.hide-user-btn {
    width: 34px;
    height: 34px;
    border: 0;
    background: transparent;
    color: $error;
    cursor: pointer;
    border-radius: 9px;
    transition: ease 0.3s;
    &:hover {
        background: rgba(0,0,0,0.1);
    }
}

.dob-selector {
    border: 1px solid $borderColor;
    padding: 5px 10px;
    border-radius: 9px;
    &::after {
        content: none;
    }
}
</style>