<template>
    <div class="modal-wrapper">
        
        <Input v-model="userTarget" name="Target" :error="errors.amount" placeholder="Enter target"/>
        <Input v-model="userLoads" name="No. of loads" :error="errors.loads" placeholder="Enter number of loads"/>
    </div>
</template>

<script>
    export default {
        props: ['target', 'date', 'modal', 'errors', 'loads'],
        data() {
            return {
                userTarget: this.target || '',
                userLoads: this.loads || '',
            }
        },
        watch: {
            userTarget() {
                this.modal.setProp('target', this.userTarget);
            },
            userLoads() {
                this.modal.setProp('loads', this.userLoads);
            }
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
    display: grid;
    gap: 20px;
}
</style>