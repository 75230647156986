import moment from "moment";
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(['adminToken']),
        overview_years() {
            let year = moment().year() + 1;
            let list = [];

            while(year >= 2023) {
                list.push({ name: year.toString(), value: year });
                year--;
            }

            return list;
        }
    },
    data() {
        return {
            target: {
                value: null,
                date: moment().toDate()
            },
            overview: {
                stats: null,
                chart: null,
                total: {
                    gross: 0,
                    count: 0,
                },
                showIgnored: false,
                year: moment().year()
            },
            overviewMonthly: {
                stats: null,
                chart: null,
                total: {
                    gross: 0,
                    count: 0,
                },
                showIgnored: false,
                date: moment().startOf('month')
            },
            stats_current: {
                range: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').toDate(),
                },
                stats: null
            },
            dispatchers: {
                range: {
                    start: moment().startOf('day').toDate(),
                    end: moment().startOf('day').toDate(),
                },
                list: []
            }
        }
    },
    mounted() {
        console.log(this.stats_current.range);
    },
    methods: {
        stats_get_target(forced = false) {
            let date = moment(this.target.date).format('MM-DD-YYYY');
            this.ajax('Stats-GetTarget' + (forced ? '-forced' : ''), {
                method: 'GET',
                url: `/stats/target/${date}`
            }, (err, body) => {
                if(!err) {
                    this.target.value = body;
                }
            });
        },
        stats_get_current(forced = false) {
            let date1 = moment(this.stats_current.range.start).format('MM-DD-YYYY');
            let date2 = moment(this.stats_current.range.end).format('MM-DD-YYYY');
            this.ajax('Stats-GetCurrent' + (forced ? '-forced' : ''), {
                method: 'GET',
                url: `/stats/general/${date1}/${date2}`
            }, (err, body) => {
                if(!err) {
                    this.stats_current.stats = body;
                }
            });
        },
        stats_get_overview(forced = false) {
            this.ajax('Stats-GetOverview' + (forced ? '-forced' : ''), {
                method: 'GET',
                url: '/stats/yearly/' + this.overview.year + (this.overview.showIgnored ? '?showIgnored' : '') 
            }, (err, body) => {
                if(!err) {
                    this.overview.stats = body.stats;
                    this.overview.chart = body.chart;
                    this.overview.total = body.total;
                }
            });
        },
        stats_get_overview_monthly(forced = false) {
            this.ajax('Stats-GetOverviewMonthly' + (forced ? '-forced' : ''), {
                method: 'GET',
                url: '/stats/monthly/' + moment(this.overviewMonthly.date).format("MM-DD-YYYY") + (this.overviewMonthly.showIgnored ? '?showIgnored' : '') 
            }, (err, body) => {
                if(!err) {
                    this.overviewMonthly.stats = body.stats;
                    this.overviewMonthly.chart = body.chart;
                    this.overviewMonthly.total = body.total;
                }
            });
        },
        stats_get_dispatchers(forced = false) {
            let date1 = moment(this.dispatchers.range.start).format('MM-DD-YYYY');
            let date2 = moment(this.dispatchers.range.end).format('MM-DD-YYYY');
            this.ajax('Stats-GetDispatchers' + (forced ? '-forced' : ''), {
                method: 'GET',
                url: `/stats/dispatchers/${date1}/${date2}`
            }, (err, body) => {
                if(!err) {
                    this.dispatchers.list = body;
                }
            });
        },
        async stats_set_target(date, amount, loads, callback) {
            date = moment(date).format('MM-DD-YYYY');
            await this.ajax('Stats-SetTarget', {
                method: 'POST',
                url: `/stats/target`,
                data: {
                    date,
                    amount,
                    loads
                }
            }, callback);
        },
        async stats_set_dispatch_target(date, amount, loads, userId, callback) {
            date = moment(date).format('MM-DD-YYYY');
            await this.ajax('Stats-SetDispatchTarget', {
                method: 'POST',
                url: `/stats/target/users`,
                data: {
                    date,
                    amount,
                    loads,
                    id: userId
                }
            }, callback);
        },
        async stats_get_dispatch_target(id, date, callback) {
            await this.ajax('Stats-GetDispatchTarget', {
                method: 'GET',
                url: `/stats/target/users/${id}/${date}`
            }, callback);
        },
    }
}