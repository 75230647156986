<template>
    <div class="chart" id="chart" ref="chart">

    </div>
</template>

<script>
import ApexCharts from 'apexcharts';

    export default {
        props: ['height', 'width'],
        mounted() {

            let that = this;

            var options = {
                series: [{
                    name: 'Gross',
                    type: 'column',
                    data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
                }, {
                    name: 'No. of loads',
                    type: 'column',
                    data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16],
                }],
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                },
                legend: {
                    show: false
                },
                stroke: {
                    width: [2, 2],
                    colors: ['#fff', '#fff', '#fff']
                },
                dataLabels: {
                    enabled: false,

                },
                labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
                yaxis: [
                    {},
                    {
                        opposite: true,
                    }
                ],
                fill: {
                    colors: ['#4653FF', '#373737']
                },
                colors: ['#4653FF', '#373737'],
                plotOptions: {
                    bar: {
                        borderRadius: 10,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
            };

            var chart = new ApexCharts(this.$refs['chart'], options);
            chart.render();

        }
    }
</script>

<style lang="scss" scoped>
.chart {
    width: 100%;
}
</style>