import TargetModal from '../components/Stats/TargetModal';

import Stats from './Stats';

export default {
    mixins: [Stats],
    methods: {
        ui_stats_set_target(currentTarget = '0', currentLoads = '0', currentDate = moment().toDate(), callback) {
            
            let modal = this.$ShowModal({
                title: `Set target`,
                component: TargetModal,
                width: '492px',
                props: {
                    target: currentTarget,
                    loads: currentLoads,
                    date: currentDate,
                    errors: {}
                }
            });

            modal.onConfirm(async (props) => {
                modal.processing(true);
                await this.stats_set_target(props.date, props.target, props.loads, (err, body) => {
                    if(!err) {
                        modal.close();
                    } else {
                        if(body.errors) modal.setProp('errors', body.errors);
                        else alert(body.message || "Something went wrong. Please, try again!");
                    }
                    callback(err, body);
                });
                modal.processing(false);

            })

        },
        ui_stats_set_dispatch_target(currentTarget = '0', currentLoads = '0',  currentDate = moment().toDate(), userId, callback) {
            
            let modal = this.$ShowModal({
                title: `Set target`,
                component: TargetModal,
                width: '492px',
                props: {
                    target: currentTarget,
                    loads: currentLoads,
                    date: currentDate,
                    errors: {}
                }
            });

            modal.onConfirm(async (props) => {
                modal.processing(true);
                await this.stats_set_dispatch_target(props.date, props.target, props.loads, userId, (err, body) => {
                    if(!err) {
                        modal.close();
                    } else {
                        if(body.errors) modal.setProp('errors', body.errors);
                        else alert(body.message || "Something went wrong. Please, try again!");
                    }
                    callback(err, body);
                });
                modal.processing(false);
            });

        }
    },
}