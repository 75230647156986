<template>
    <div class="modal-wrapper">
        
        <div v-if="details" class="page-grid">
            
            <div class="dispatcher-name-group">
                <img src="@/assets/profile-logo.png" class="dispatcher-image">
                <h3>{{ details.dispatcher.name }}</h3>
            </div>


            <div class="stats">
                <div class="stats-item">
                    <div class="title">Gross Income</div>
                    <div class="value">${{ CONVERT_PRICE(details.total.gross.toFixed(2)) }}</div>
                </div>

                <div class="vl"></div>

                <div class="stats-item">
                    <div class="title">No. of Loads</div>
                    <div class="value">{{ details.total.count }}</div>
                </div>
            </div>


            <DashboardItem class="dashboard-item" title="Monthly Target" :loading="isLoading(['Stats-GetDispatchTarget'])">
                <template v-slot:action>
                    <div class="target-month-switcher">
                        <button class="switch" @click="targetSwitchMonth(-1)"><i class="fa-solid fa-chevron-left"></i></button>
                        <DropDownDatePicker position="right" v-model="target.date" displayFormat="MMMM, YYYY"/>
                        <button class="switch" @click="targetSwitchMonth(1)"><i class="fa-solid fa-chevron-right"></i></button>
                    </div>
                </template>
                <template v-slot:main>
                    <div v-if="target.value" style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 20px;">
                        <div class="target-container">
                            <div class="target-info">
                                <div class="target-percent">{{ target.value.percent }}%</div>
                                <div class="target-amount"><a href="" @click.prevent="setTarget">${{ CONVERT_PRICE(target.value.amount.toFixed(0)) }}</a></div>
                            </div>
                            <div class="bar-holder">
                                <div class="bar" :style="`width: ${target.value.percent}%`"></div>
                            </div>
                            <div v-if="target.value.percent >= 100" class="target-completed-text">Congrats! You smashed it</div>
                            <div v-else class="target-completed-text"><span style="font-weight: 600;">${{ CONVERT_PRICE(target.value.left.toFixed(2)) }}</span> left to go</div>
                        </div>

                        <div class="target-container" v-if="target.value.loads">
                            <div class="target-info">
                                <div class="target-percent">{{ target.value.loads.percent }}%</div>
                                <div class="target-amount"><a href="" @click.prevent="setTarget">{{ target.value.loads.amount }} {{ target.value.loads.amount === 1 ? 'load' : 'loads' }}</a></div>
                            </div>
                            <div class="bar-holder">
                                <div class="bar" :style="`width: ${target.value.loads.percent}%`"></div>
                            </div>
                            <div v-if="target.value.loads.percent >= 100" class="target-completed-text">Congrats! You smashed it</div>
                            <div v-else class="target-completed-text"><span style="font-weight: 600;">{{ target.value.loads.left }}</span> {{ target.value.loads.left === 1 ? 'load' : 'loads' }} left to go</div>
                        </div>

                    </div>
                    <div style="font-size: 16px; display: flex; align-items: center; height: 100%;" v-else><a href="" @click.prevent="setTarget">Target is not set</a></div>
                </template>
            </DashboardItem>


            <DashboardItem class="dashboard-item" title="Gross Overview">
                <template v-slot:action>
                    <DropDownList position="right" :options="overview_years" selectAttribute="value" showAttribute="name" v-model="year"/>
                </template>
                <template v-slot:main>
                    <AreaChart v-if="details.chart" :sparkline="false" :height="300" :series="[{ name: 'Gross', data: details.chart.gross }]" :labels="details.chart.labels" :labelFormat="displayPrice" :yLabelFormat="convertPrice"/>
                </template>
            </DashboardItem>

            <DashboardItem class="dashboard-item" title="No. of Loads">
                <template v-slot:action>
                    <DropDownList position="right" :options="overview_years" selectAttribute="value" showAttribute="name" v-model="year"/>
                </template>
                <template v-slot:main>
                    <AreaChart v-if="details.chart" :sparkline="false" :height="300" :series="[{ name: 'No. of Loads', data: details.chart.count }]" :labels="details.chart.labels"/>
                </template>
            </DashboardItem>


        </div>
        
    </div>
</template>

<script>
import Dispatchers from '../../mixins/Dispatchers';
import AreaChart from '../../components/ApexCharts/AreaChart.vue'
import DashboardItem from '../../components/DashboardItem.vue'
import moment from 'moment';

import Stats from '../../mixins/Stats'
import { mapGetters } from 'vuex';

import UI_Stats from '../../mixins/UI_Stats';

    export default {
        components: {
            AreaChart,
            DashboardItem
        },
        mixins: [Dispatchers, Stats, UI_Stats],
        props: ['modal', 'id'],
        data() {
            return {
                details: null,
                year: moment().year(),
                target: {
                    date: moment().toDate(),
                    value: null
                }
            }
        },
        watch: {
            year() {
                this.getDetails();
            },
            "target.date"() {
                this.getTarget();
            }
        },
        computed: {
            ...mapGetters(['adminToken'])
        },
        methods: {
            targetSwitchMonth(val) {
                let date = moment(this.target.date).startOf('month').add(val, 'months');
                this.target.date = date.toDate();
            },
            async getDetails() {
                this.modal.processing(true);
                await this.dispatchers_get_overview(this.id, this.year, (err, body) => {
                    if(!err) {
                        this.details = body;
                    } else {
                        this.modal.close();
                    }
                });
                this.modal.processing(false);
            },
            displayPrice(val) {
                let formatter = Intl.NumberFormat("en", { notation: "compact", maximumSignificantDigits: 3 });
                val = formatter.format(val);
                return '$' + val;
            },
            convertPrice(val) {
                return '$' + this.CONVERT_PRICE(val.toFixed(2));
            },
            setTarget() {
                if(!this.adminToken) return;
                this.ui_stats_set_dispatch_target(this.target.value ? this.target.value.amount : '',  this.target.value ? this.target.value.loads.amount : '', this.target.date, this.id, (err, body) => {
                    if(!err) {
                        this.getTarget();
                    }
                });
            },
            getTarget() {
                this.stats_get_dispatch_target(this.id, moment(this.target.date).format("MM-DD-YYYY"), (err, body) => {
                    if(!err) {
                        this.target.value = body;
                    }
                });
            }
        },
        mounted() {
            this.getDetails();
            this.getTarget();
        }
    }
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 20px;
}

.page-grid {
    display: grid;
    gap: 30px;
}

.dispatcher-image {
    width: 150px;
    height: 150px;
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    border-radius: 50%;
}


.target-container {
    display: grid;
    gap: 5px;
    font-size: 14px;
    .bar-holder {
        width: 100%;
        height: 16px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        background: rgba(0,0,0,0.1);
        box-shadow: $boxShadow;
        border: 1px solid $borderColor;
        .bar {
            position: absolute;
            height: 100%;
            width: 0;
            background: $themeColor2;
            transition: ease 0.3s;
            border-radius: 4px;
        }
    }
    .target-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .target-percent {
            
        }
        .target-amount {
            font-weight: 500;
        }
    }
    .target-completed-text {
        color: $themeColor2;
        font-size: 14px;
        font-weight: 400;
    }
}

.dashboard-item {
    // padding: 0;
    // border: 0;
    // background: transparent;
    // box-shadow: none;
}

.dispatcher-name-group {
    display: grid;
    justify-content: center;
    justify-items: center;
    gap: 20px;
}



.stats {
    width: 100%;
    display: flex;
    border: 1px solid $borderColor;
    background: $themeColor1;
    box-shadow: $boxShadow;
    padding: 20px 10px;
    border-radius: 20px;
    align-items: center;
    .vl {
        width: 1px;
        height: 40px;
        background: $borderColor;
    }
    .stats-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 7px;
        .title {
            text-transform: uppercase;
            font-size: 11px;
            color: rgba(0,0,0,0.5);
            text-align: center;
        }
        .value {
            text-align: center;
            font-size: 22px;
            font-weight: 500;
        }
    }
}



.target-month-switcher {
    display: grid;
    grid-template-columns: auto 150px auto;
    align-items: center;
    gap: 10px;
    button.switch {
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 6px;
        color: $textLight;
        background: transparent;
        cursor: pointer;
        font-size: 12px;
        &:hover {
            color: $textShade;
            background: rgba(0,0,0,0.1);
        }
    }
}

</style>