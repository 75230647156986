import DispatcherDetailsModal from '../components/Dispatchers/Details'
import DispatchersManager from '../components/Dispatchers/Manage'

export default {
    methods: {
        ui_dispatchers_details(id) {
            let modal = this.$ShowModal({
                title: `User's Details`,
                component: DispatcherDetailsModal,
                width: '592px',
                props: {
                    id
                },
                confirm: 'Close',
                hideCancelButton: true
            });

            modal.onConfirm(async (props) => {
                modal.close();
            });
        },
        ui_manage_dispatchers() {

            let modal = this.$ShowModal({
                title: `Users`,
                component: DispatchersManager,
                width: '592px',
                confirm: 'Close',
                hideCancelButton: true
            });

            modal.onConfirm(async (props) => {
                modal.close();
            });
        }
    },
}