<template>
    <div class="chart" id="chart" ref="chart">

    </div>
</template>

<script>
import ApexCharts from 'apexcharts';

    export default {
        props: ['height', 'width'],
        mounted() {

            var options = {
                chart: {
                    type: 'area',
                    height: this.height || 400,
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: false
                    },
                },
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                dataLabels: {
                    enabled: false
                },
                series: [{
                    name: 'sales',
                    data: [30,40,35]
                }],
                xaxis: {
                    categories: [1991,1992,1993],
                    crosshairs: {
                        width: 1
                    },
                },
            }

            var chart = new ApexCharts(this.$refs['chart'], options);
            chart.render();

        }
    }
</script>

<style lang="scss" scoped>
.chart {
    width: 100%;
}
</style>