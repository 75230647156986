<template>
    <div class="wrapper">

        <StatsItem text="Monthly Target" icon="fa-solid fa-bullseye" :horizontal="false" :loading="isLoading(['Stats-GetTarget'])">
            <template v-slot:main>
                
                <div v-if="target.value" style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr)); gap: 50px;">

                    <div class="target-container">
                        <div class="target-info">
                            <div class="target-percent">{{ target.value.percent }}%</div>
                            <div class="target-amount"><a href="" @click.prevent="setTarget">${{ CONVERT_PRICE(target.value.amount.toFixed(0)) }}</a></div>
                        </div>
                        <div class="bar-holder">
                            <div class="bar" :style="`width: ${target.value.percent}%`"></div>
                        </div>
                        <div v-if="target.value.percent >= 100" class="target-completed-text">Congrats! You smashed it</div>
                        <div v-else class="target-completed-text"><span style="font-weight: 600;">${{ CONVERT_PRICE(target.value.left.toFixed(2)) }}</span> left to go</div>
                    </div>

                    <div class="target-container" v-if="target.value.loads">
                        <div class="target-info">
                            <div class="target-percent">{{ target.value.loads.percent }}%</div>
                            <div class="target-amount"><a href="" @click.prevent="setTarget">{{ target.value.loads.amount }} {{ target.value.loads.amount === 1 ? 'load' : 'loads' }}</a></div>
                        </div>
                        <div class="bar-holder">
                            <div class="bar" :style="`width: ${target.value.loads.percent}%`"></div>
                        </div>
                        <div v-if="target.value.loads.percent >= 100" class="target-completed-text">Congrats! You smashed it</div>
                        <div v-else class="target-completed-text"><span style="font-weight: 600;">{{ target.value.loads.left }}</span> {{ target.value.loads.left === 1 ? 'load' : 'loads' }} left to go</div>
                    </div>

                </div>
                <div style="font-size: 16px; display: flex; align-items: center; height: 100%;" v-else><a href="" @click.prevent="setTarget">Target is not set</a></div>

                
            </template>
            <template v-slot:action>
                <div class="target-month-switcher">
                    <button class="switch" @click="targetSwitchMonth(-1)"><i class="fa-solid fa-chevron-left"></i></button>
                    <DropDownDatePicker position="right" v-model="target.date" displayFormat="MMMM, YYYY"/>
                    <button class="switch" @click="targetSwitchMonth(1)"><i class="fa-solid fa-chevron-right"></i></button>
                </div>
            </template>
        </StatsItem>


        <DashboardItem title="Monthly Overview" :loading="isLoading(['Stats-GetOverviewMonthly'])">
            <template v-slot:action>
                <div style="display: flex; align-items: center; gap: 30px;">
                    <div style="display: flex; align-items: center; gap: 10px;">
                        <span>Display Ignored</span>
                        <Switch v-model="overviewMonthly.showIgnored"/>
                    </div>

                    <div class="target-month-switcher">
                        <button class="switch" @click="monthlyOverviewSwitchMonth(-1)"><i class="fa-solid fa-chevron-left"></i></button>
                        <DropDownDatePicker position="right" v-model="overviewMonthly.date" displayFormat="MMMM, YYYY"/>
                        <button class="switch" @click="monthlyOverviewSwitchMonth(1)"><i class="fa-solid fa-chevron-right"></i></button>
                    </div>
                </div>
            </template>
            <template v-slot:main>
                <div class="overview-container">
                    <div class="overview-stats">

                        <div class="stats-item">
                            <div class="name">Gross Income</div>
                            <div class="value">${{ CONVERT_PRICE(overviewMonthly.total.gross.toFixed(2)) }}</div>
                        </div>

                        <div class="stats-item">
                            <div class="name">No. of Loads</div>
                            <div class="value">{{ CONVERT_PRICE(overviewMonthly.total.count.toFixed(0)) }}</div>
                        </div>

                    </div>
                    <AreaChart v-if="overviewMonthly.chart" :sparkline="false" :height="250" :series="[{ name: 'Gross', data: overviewMonthly.chart.gross }]" :labels="overviewMonthly.chart.labels" :labelFormat="displayPrice" :yLabelFormat="convertPrice"/>
                    <AreaChart v-if="overviewMonthly.chart" :sparkline="false" :height="250" :series="[{ name: 'No. of loads', data: overviewMonthly.chart.count }]" :labels="overviewMonthly.chart.labels"/>
                </div>
            </template>
        </DashboardItem>


        <DashboardItem title="Yearly Overview" :loading="isLoading(['Stats-GetOverview'])">
            <template v-slot:action>
                <div style="display: flex; align-items: center; gap: 30px;">
                    <div style="display: flex; align-items: center; gap: 10px;">
                        <span>Display Ignored</span>
                        <Switch v-model="overview.showIgnored"/>
                    </div>
                    <DropDownList position="right" :options="overview_years" selectAttribute="value" showAttribute="name" v-model="overview.year"/>
                </div>
            </template>
            <template v-slot:main>
                <div class="overview-container">
                    <div class="overview-stats">

                        <div class="stats-item">
                            <div class="name">Gross Income</div>
                            <div class="value">${{ CONVERT_PRICE(overview.total.gross.toFixed(2)) }}</div>
                        </div>

                        <div class="stats-item">
                            <div class="name">No. of Loads</div>
                            <div class="value">{{ CONVERT_PRICE(overview.total.count.toFixed(0)) }}</div>
                        </div>

                    </div>
                    <AreaChart v-if="overview.chart" :sparkline="false" :height="250" :series="[{ name: 'Gross', data: overview.chart.gross }]" :labels="overview.chart.labels" :labelFormat="displayPrice" :yLabelFormat="convertPrice"/>
                    <AreaChart v-if="overview.chart" :sparkline="false" :height="250" :series="[{ name: 'No. of loads', data: overview.chart.count }]" :labels="overview.chart.labels"/>
                </div>
            </template>
        </DashboardItem>

        


        <DashboardItem title="Dispatchers" :loading="isLoading(['Stats-GetDispatchers'])">
            <template v-slot:action>
                <div style="display: flex; gap: 20px; align-items: center;">
                    <DropDownDatePicker position="right" v-model="dispatchers.range" :range="true"/>
                    <button class="manage-btn" @click="ui_manage_dispatchers" v-if="adminToken"><i class="fa-solid fa-gear"></i></button>
                </div>
            </template>
            <template v-slot:main>
                <div style="max-height: 500px; overflow-y: auto;">
                    <Table :head="[{ name: '' }, { name: 'Name' }, { name: 'Gross Income' }, { name: 'No. of Loads' }]" :stickyHead="true" :size="['44px', 'minmax(0, 1fr)', 'minmax(100px, 150px)', 'minmax(100px, 150px)']">
                        <TransitionGroup name="list">
                            <TableRow :size="['44px', 'minmax(0, 1fr)', 'minmax(100px, 150px)', 'minmax(100px, 150px)']" :key="item._id" v-for="item of dispatchers.list" >
                                <div class="dispatcher-initials">{{item.dispatcher.initials}}</div>
                                <div class="dispatcher-name"><a href="" @click.prevent="ui_dispatchers_details(item.dispatcher.id)">{{ item.dispatcher.name }}</a></div>
                                <div>$ {{ CONVERT_PRICE(item.gross.toFixed(2)) }}</div>
                                <div style="font-weight: 700;">{{ item.count }} <span style="color: rgba(0,0,0,0.5)">{{ item.count === 1 ? 'load' : 'loads' }}</span></div>
                            </TableRow>
                        </TransitionGroup>
                    </Table>
                </div>
            </template>
        </DashboardItem>


        <DashboardItem title="Birthdays" v-if="birthdays.length > 0">
            <template v-slot:main>
                <Table :head="[{ name: 'Name' }, { name: 'Date of Birth' }, { name: '' }]" :stickyHead="true" :size="['minmax(0, 1fr)', 'minmax(100px, 150px)', 'minmax(100px, 150px)']">
                    <TableRow :size="['minmax(0, 1fr)', 'minmax(100px, 150px)', 'minmax(100px, 150px)']" :key="item._id" v-for="item of birthdays" >
                        <div class="dispatcher-name"><a href="" @click.prevent="">{{ item.name }}</a></div>
                        <div>{{ convertDOB(item.dob) }}</div>
                        <div>
                            <div v-if="item.daysTillNextBirthday >= 2">in <strong>{{ item.daysTillNextBirthday }}</strong> <span style="color: rgba(0,0,0,0.5)">{{ item.daysTillNextBirthday === 1 ? 'day' : 'days' }}</span></div>
                            <div v-else>
                                <span v-if="item.daysTillNextBirthday === 0"><strong>Today</strong></span>
                                <span v-if="item.daysTillNextBirthday === 1"><strong>Tomorrow</strong></span>
                            </div>
                        </div>
                    </TableRow>
                </Table>
            </template>
        </DashboardItem>

    </div>
</template>

<script>

import ApexLineChart from '../components/ApexCharts/LineChart.vue'
import ApexStackedColumns from '../components/ApexCharts/StackedColumns.vue'
import DashboardItem from '../components/DashboardItem.vue';


import Stats from '../mixins/Stats';
import UI_Stats from '../mixins/UI_Stats'
import OverviewChartVue from '../components/ApexCharts/OverviewChart.vue';
import ColumnsChart from '../components/ApexCharts/ColumnsChart.vue';
import AreaChart from '../components/ApexCharts/AreaChart.vue';
import StatsItem from '../components/StatsItem.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';


import UI_Dispatchers from '../mixins/UI_Dispatchers'
import Dispatchers from '../mixins/Dispatchers';

export default {
        mixins: [Stats, UI_Stats, UI_Dispatchers, Dispatchers],
        components: {
            ApexLineChart,
            ApexStackedColumns,
            OverviewChartVue,
            ColumnsChart,
            AreaChart,
            DashboardItem,
            StatsItem
        },
        data() {
            return {
                birthdays: []
            }
        },
        computed: {
            ...mapGetters(['adminToken']),
        },
        methods: {
            targetSwitchMonth(val) {
                let date = moment(this.target.date).startOf('month').add(val, 'months');
                this.target.date = date.toDate();
            },
            monthlyOverviewSwitchMonth(val) {
                let date = moment(this.overviewMonthly.date).startOf('month').add(val, 'months');
                this.overviewMonthly.date = date.toDate();
            },
            convertPrice(val) {
                return '$' + this.CONVERT_PRICE(val.toFixed(2));
            },
            displayPrice(val) {
                let formatter = Intl.NumberFormat("en", { notation: "compact", maximumSignificantDigits: 3 });
                val = formatter.format(val);
                return '$' + val;
            },
            setTarget() {
                if(!this.adminToken) return;
                this.ui_stats_set_target(this.target.value ? this.target.value.amount : '', this.target.value ? this.target.value.loads.amount : '', this.target.date, (err, body) => {
                    if(!err) {
                        this.stats_get_target();
                    }
                });
            },
            getBirthdays() {
                this.users_get_birthdays((err, body) => {
                    if(!err) {
                        this.birthdays = body;
                    }
                });
            },
            convertDOB(dob) {
                return moment.utc(dob).format("MMMM D, YYYY")
            }
        },
        watch: {
            "stats_current.range"() {
                this.stats_get_current();
            },
            "dispatchers.range"() {
                this.stats_get_dispatchers();
            },
            "overview.year"() {
                this.stats_get_overview();
            },
            "overview.showIgnored"() {
                this.stats_get_overview();
            },
            "target.date"() {
                this.stats_get_target();
            },
            "overviewMonthly.date"() {
                this.stats_get_overview_monthly();
            },
            "overviewMonthly.showIgnored"() {
                this.stats_get_overview_monthly();
            },
        },
        mounted() {
            this.stats_get_overview();
            this.stats_get_overview_monthly();
            this.stats_get_current();
            this.stats_get_dispatchers();
            this.stats_get_target();
            this.getBirthdays();


            this.$WebSocket.on('open', 'dashboard', () => {
                this.stats_get_overview(true);
                this.stats_get_current(true);
                this.stats_get_dispatchers(true);
                this.stats_get_target(true);
                this.stats_get_overview_monthly(true);
            });

            this.$WebSocket.on('/targets/update', 'dashboard', () => {
                this.stats_get_target(true);
            });

            this.$WebSocket.on('/users/refresh-birthdays', 'dashboard', () => {
                this.getBirthdays();
            });

            this.$WebSocket.on('/stats/update', 'dashboard', () => {
                this.stats_get_overview(true);
                this.stats_get_current(true);
                this.stats_get_dispatchers(true);
                this.stats_get_overview_monthly(true);
            });

        }
    }
</script>

<style lang="scss" scoped>

.wrapper {
    // max-width: none;
    display: grid;
    gap: 30px;
}

.manage-btn {
    border: 0;
    background: transparent;
    width: 28px;
    height: 28px;
    border-radius: 7px;
    color: $text;
    cursor: pointer;
    &:hover {
        background: rgba(0,0,0,0.05);
    }
}

.target-container {
    display: grid;
    gap: 5px;
    font-size: 14px;
    .bar-holder {
        width: 100%;
        height: 16px;
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        background: rgba(0,0,0,0.1);
        box-shadow: $boxShadow;
        border: 1px solid $borderColor;
        .bar {
            position: absolute;
            height: 100%;
            width: 0;
            background: $themeColor2;
            transition: ease 0.3s;
            border-radius: 4px;
        }
    }
    .target-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        .target-percent {
            
        }
        .target-amount {
            font-weight: 500;
        }
    }
    .target-completed-text {
        color: $themeColor2;
        font-size: 14px;
        font-weight: 400;
    }
}

.overview-container {
    display: grid;
    grid-template-columns: 200px minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
    .overview-stats {
        border-right: 1px solid $borderColor;
    }
    .stats-item {
        border-bottom: 1px solid $borderColor;
        padding: 20px 0;
        display: grid;
        gap: 10px;
        .name {
            font-weight: 500;
            text-transform: uppercase;
            font-size: 11px;
            color: $textLight;
        }
        .value {
            font-weight: 600;
            font-size: 16px;
            color: $text;
        }
    }
}

.month-picker__container {
    width: 320px;
}

.month-picker {
    gap: 20px !important;
    padding: 20px;
}

.dispatcher-initials {
    width: 44px;
    height: 44px;
    background: $bgShade;
    border-radius: 9px;
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $themeColor2;
    font-size: 14px;
}

.dispatcher-name {
    
}

.stats-block {
    .gross {
        font-weight: 500;
        font-size: 24px;
        color: $themeColor2;
    }
    .count {
        font-weight: 500;
        font-size: 14px;
    }
}


.list-move, .list-enter-active, .list-leave-active {
    transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

.list-enter-from, .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

.target-month-switcher {
    display: grid;
    grid-template-columns: auto 150px auto;
    align-items: center;
    gap: 10px;
    button.switch {
        width: 24px;
        height: 24px;
        border: none;
        border-radius: 6px;
        color: $textLight;
        background: transparent;
        cursor: pointer;
        font-size: 12px;
        &:hover {
            color: $textShade;
            background: rgba(0,0,0,0.1);
        }
    }
}
</style>