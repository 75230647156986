<template>
    <div class="chart" id="chart" ref="chart">

    </div>
</template>

<script>
import ApexCharts from 'apexcharts';

    export default {
        props: ['height', 'width', 'chart'],
        mounted() {

            let that = this;

            var options = {
                series: [{
                    name: 'Gross',
                    type: 'column',
                    data: this.chart.gross
                }],
                chart: {
                    height: 350,
                    type: 'line',
                    toolbar: {
                        show: false
                    },
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: false
                    },
                },
                legend: {
                    show: false
                },
                stroke: {
                    width: [2, 2],
                    colors: ['#fff', '#fff', '#fff']
                },
                dataLabels: {
                    enabled: false,

                },
                labels: this.chart.labels,
                yaxis: [
                    {},
                    { opposite: true }
                ],
                fill: {
                    colors: ['#4653FF', '#373737']
                },
                colors: ['#4653FF', '#373737'],
                plotOptions: {
                    bar: {
                        borderRadius: 3,
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
            };

            var chart = new ApexCharts(this.$refs['chart'], options);
            chart.render();

        }
    }
</script>

<style lang="scss" scoped>
.chart {
    width: 100%;
}
</style>