export default {
    methods: {
        async dispatchers_get_overview(id, year, callback) {
            await this.ajax('Dispatchers-GetOverview', {
                method: 'GET',
                url: `/stats/dispatcherOverview/${id}/${year}`
            }, callback);
        },
        async users_get(callback) {
            await this.ajax('Users-Get', {
                method: 'GET',
                url: `/users`
            }, callback);
        },
        async users_set_hidden_option(id, callback) {
            await this.ajax('Users-SetHiddenOption', {
                method: 'PUT',
                url: `/users/hide/${id}`
            }, callback);
        },
        async users_set_ignore_option(id, value, callback) {
            await this.ajax('Users-SetIgnoreOption', {
                method: 'PUT',
                url: `/users/ignore/${id}`,
                data: {
                    ignore: value
                }
            }, callback);
        },
        async users_set_dob(id, value, callback) {
            await this.ajax('Users-SetDOB', {
                method: 'PUT',
                url: `/users/dob/${id}`,
                data: {
                    date: value
                }
            }, callback);
        },
        async users_get_birthdays(callback) {
            await this.ajax('Users-GetBirthdays', {
                method: 'GET',
                url: `/users/birthdays`
            }, callback);
        }
    },
}